import { IconButton, Modal } from '@material-ui/core';
import { CheckCircleOutline, Edit, HighlightOff } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import TableEncuesta from '../../../components/common/TableEncuesta';
import Alert from '../../../components/common/Alert';
import { useHistory, useLocation } from 'react-router-dom';
import Menu, { MenuContainer } from '../../../components/common/Menu';
import Button from '../../../components/common/Button';
import ModalContent from '../../../components/common/Modal/ModalContent';
import SurveysAPI from '../survey-api';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import { SurveyModalRelationGroup } from '../../../components/common/Modal/SurveyRelationGroup';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SurveyAPI from '../../AnnouncementContainer/survey-api';
import AlertPop from '../../../components/common/AlertPop';

const LIMIT_PER_PAGE = 10;
const SurveyTableContainer = () => {
  const history = useHistory();
  const [survey, setSurvey] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState({ type: '', text: '' });

  const [isMenuOpen, setIsMenuOpen] = useState({ open: false, id: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [dateRangeSelected, setDateRangeSelected] = useState([null, null]);
  const [filter, setFilter] = useState({});
  const [reloadDate, setReloadDate] = useState('');
  const [reloadDataTable, setReloadDataTable] = useState(false);
  const [openModalRelationGroup, setOpenModalRelationGroup] = useState(false);
  const [rowSelected, setRowSelected] = useState([]);
  const [clientID, setClientID] = useState('');
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [infoAlert, setInfoAlert] = useState({
    title: '',
    description: '',
    actions: {},
  });

  const [expandedRows, setExpandedRows] = useState({});

  const groupedData = (data) => {
    return data.reduce((acc, item) => {
      const { groupName } = item;
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(item);
      return acc;
    }, {});
  };

  const fetchSurveys = useCallback(async () => {
    let arrData = [];
    let totalData = 0;
    let dateRefresh = '';
    let count = 0;

    await SurveysAPI.getAllSurveys(currentPage, LIMIT_PER_PAGE, filter, refresh)
      .then((response) => {
        if (response.data.data.length > 0) {
          const groups = groupedData(response.data.data);
          Object.entries(groups).forEach(([groupName, data]) => {
            console.log(`Grupo: ${groupName}`);
            console.log('Data:', data);
            if (groupName === 'Sin grupo') {
              console.log('Sin grupo entre aqui');
              data.map((item, index) => {
                return arrData.push({
                  nombre: item?.nombre ?? '-',
                  id: item?.id ?? '-',
                  fechaActivacion: item?.fechaActivacion ?? '-',
                  dirigidoA: item?.dirigidoA ?? '-',
                  tipoDeEncuesta: item?.tipoDeEncuesta ?? '-',
                  cantidadPreguntas: item?.cantidadPreguntas ?? '-',
                  estado: item?.estado ?? '-',
                  idEncuesta: item?.idEncuesta ?? '-',
                  urlEditar: item?.urlEditar ?? '-',
                  verEncuesta: item?.verEncuesta ?? '-',
                  verResultadosEncuesta: item?.verResultadosEncuesta ?? '-',
                  diasActiva: item?.diasActiva ?? '-',
                  groupName: groupName + index,
                  rowIndex: count++,
                  rowGroup: [],
                  haveGroup: false,
                });
              });
            } else {
              arrData.push({
                nombre: groupName ?? '-',
                id: groupName,
                fechaActivacion: '-',
                dirigidoA: '-',
                tipoDeEncuesta: '-',
                cantidadPreguntas: '-',
                estado: '-',
                idEncuesta: '-',
                urlEditar: '-',
                verEncuesta: '-',
                verResultadosEncuesta: '-',
                diasActiva: '-',
                groupName: groupName,
                rowIndex: count++,
                rowGroup: data.map((item, index) => {
                  return {
                    nombre: item?.nombre ?? '-',
                    id: item?.idEncuesta ?? '-',
                    fechaActivacion: item?.fechaActivacion ?? '-',
                    dirigidoA: item?.dirigidoA ?? '-',
                    tipoDeEncuesta: item?.tipoDeEncuesta ?? '-',
                    cantidadPreguntas: item?.cantidadPreguntas ?? '-',
                    estado: item?.estado ?? '-',
                    idEncuesta: item?.idEncuesta ?? '-',
                    urlEditar: item?.urlEditar ?? '-',
                    verEncuesta: item?.verEncuesta ?? '-',
                    verResultadosEncuesta: item?.verResultadosEncuesta ?? '-',
                    diasActiva: item?.diasActiva ?? '-',
                    haveGroup: true,
                    groupName: groupName,
                    rowIndex: groupName + index,
                  };
                }),
              });
            }
          });

          totalData = response.data.pagination.totalPages;
          if (refresh) {
            dateRefresh = response.data.lastRefresh;
          }
        }
        setSurvey(arrData);
        setTotalPages(totalData);

        setReloadDate(dateRefresh);
      })
      .catch((error) => {
        setMessage({ type: 'error', text: 'Error al cargar las encuestas.' });
      })
      .finally(() => {
        setTimeout(() => {
          setMessage({ type: '', text: '' });
        }, 10000);
        setIsLoading(false);
      });
  }, [currentPage, filter, refresh, reloadDataTable]);

  useEffect(() => {
    if (refresh) {
      fetchSurveys();
    }
  }, [refresh, fetchSurveys]);

  useEffect(() => {
    fetchSurveys();
  }, [currentPage, filter, reloadDataTable, fetchSurveys]);

  useEffect(() => {
    if (searchText !== '') {
      setIsLoading(true);
      setFilter({
        searchText: searchText,
        ...filter,
      });
    } else if (
      dateRangeSelected.length === 2 &&
      dateRangeSelected[1] !== null
    ) {
      setIsLoading(true);
      setFilter({
        dateRange: dateRangeSelected,
        ...filter,
      });
    }
  }, [dateRangeSelected, searchText]);

  const toggleRowExpanded = (rowIndex) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  const deleteGroup = async (groupName) => {
    setIsLoading(true);
    setIsMenuOpen({});
    const payload = {
      action: 'delete',
      groupName: groupName,
    };
    try {
      await SurveyAPI.relationGroup(payload).then(() => {
        setSurvey([]);
        setReloadDataTable((prev) => !prev);
        setMessage({
          type: 'success',
          text: 'Grupo de encuestas eliminado correctamente.',
        });
      });
    } catch (error) {
      setMessage({
        type: 'error',
        text: 'Error al eliminar el grupo de encuestas.',
      });
    }
    setTimeout(() => {
      setMessage({ type: '', text: '' });
    }, 10000);
  };

  const deleteByGroup = async (groupName, clientId, idEncuesta) => {
    setIsLoading(true);
    setIsMenuOpen({});
    const payload = {
      action: 'remove',
      clientId: clientId,
      groupName: groupName,
      surveyIds: [idEncuesta],
    };
    try {
      await SurveyAPI.relationGroup(payload).then(() => {
        setSurvey([]);
        setReloadDataTable((prev) => !prev);
        setMessage({
          type: 'success',
          text: 'Encuesta eliminada del grupo de encuestas correctamente.',
        });
      });
    } catch (error) {
      setMessage({
        type: 'error',
        text: 'Error al eliminar encuesta del grupo de encuestas.',
      });
    }
    setTimeout(() => {
      setMessage({ type: '', text: '' });
    }, 10000);
  };

  const onMenuClick = (id) => {
    setIsMenuOpen((prevOpenMenus) => ({
      [id]: !prevOpenMenus[id],
    }));
  };

  const surveyInZoho = (url) => {
    window.location.href = url;
  };

  const handleCopy = (urlToCopy) => {
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        setOpenAlert(true);
        setInfoAlert({
          title: (
            <CheckCircleOutline
              style={{ fontSize: '50px', color: 'limegreen' }}
            />
          ),
          description: 'URL copiada al portapapeles.',
          actions: {
            onSuccess: 'Aceptar',
            onSuccessClick: () => setIsMenuOpen({}),
          },
        });
      })
      .catch((error) => {
        setOpenAlert(true);
        setInfoAlert({
          title: <HighlightOff style={{ fontSize: '50px', color: 'red' }} />,
          description: 'Error al copiar la URL.',
          actions: {
            onSuccess: 'Aceptar',
            onSuccessClick: () => setIsMenuOpen({}),
          },
        });
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'nombre',
        width: 180,
      },
      {
        Header: 'Fecha de activación',
        width: 100,
        accessor: 'fechaActivacion',
        Cell: ({ value }) => (value === 'Fecha inválida' ? '-' : value),
      },
      {
        Header: 'Dirigido a',
        accessor: 'dirigidoA',
        width: 90,
        Cell: ({ value }) =>
          value === '' ? '-' : value === 'both' ? 'Ambos' : value,
      },
      { Header: 'Tipo de encuesta', accessor: 'tipoDeEncuesta', width: 150 },
      {
        Header: 'Número de preguntas',
        accessor: 'cantidadPreguntas',
        width: 90,
      },
      {
        Header: 'Estado',
        accessor: 'estado',
        width: 90,
        Cell: ({ value }) => {
          return (
            <div className="div-container-status-table">
              <div
                className={`point-status ${
                  value === true ? 'green' : value !== '-' ? 'with-border' : '-'
                }`}
              />
              <span>
                {value === true ? 'Activa' : value !== '-' ? 'Inactiva' : '-'}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'Acción',
        width: 150,
        Cell: ({ row: { original } }, i) => {
          const listItems = [];
          if (original.rowGroup?.length > 0) {
            listItems.push({
              text: 'Desagrupar',
              key: 'desagrupar',
              action: () => deleteGroup(original.groupName),
            });
          } else {
            listItems.push(
              {
                text: 'Copiar URL de encuesta',
                key: 'verDetalle',
                status: original.verEncuesta === '' ? 'disabled' : 'active',
                action: () => handleCopy(original.verEncuesta),
              },
              {
                text: 'Ver resultados en Zoho',
                key: 'verResultados',
                tooltip: 'Los resultados no se encuentran disponibles',
                placement: 'left',
                status:
                  original.verResultadosEncuesta === '' ? 'disabled' : 'active',
                action: () => surveyInZoho(original.verResultadosEncuesta),
              },
              {
                text: 'Editar encuesta en Zoho',
                key: 'editar',
                status: original.urlEditar === '' ? 'disabled' : 'active',
                action: () => surveyInZoho(original.urlEditar),
              }
            );

            // if (original.haveGroup) {
            //   listItems.push({
            //     text: 'Quitar del grupo',
            //     key: 'quitar',
            //     action: () =>
            //       deleteByGroup(
            //         original.groupName,
            //         clientId,
            //         original.idEncuesta
            //       ),
            //   });
            // }
          }

          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <MenuContainer
                classActive={`${
                  isMenuOpen[original.id] ? 'active-action' : ''
                }`}
                onClick={() => onMenuClick(original.id)}
                key={original.idEncuesta}
              >
                <Menu
                  open={isMenuOpen[original.id] ?? false}
                  items={listItems}
                  id={original.id}
                  row={original.rowGroup}
                />
              </MenuContainer>
              <div>
                {original.rowGroup?.length > 0 ? (
                  <IconButton
                    style={{
                      color: '#0bb5e7',
                    }}
                    onClick={() => toggleRowExpanded(original.rowIndex)}
                  >
                    <ExpandMoreIcon
                      style={{
                        transform: `${
                          expandedRows[original.rowIndex]
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)'
                        }`,
                        transition: 'transform 0.3s ease',
                      }}
                    />
                  </IconButton>
                ) : (
                  <div style={{ width: '48px', height: '48px' }}></div>
                )}
              </div>
            </div>
          );
        },
      },
    ],
    [history, isMenuOpen, expandedRows]
  );

  const onRelationSurvey = (survey, clientId) => {
    setRowSelected(survey);
    setOpenModalRelationGroup(true);
    setClientID(clientId);
  };

  const onSearch = (value) => {
    setSearchText(value);
  };

  const resetFilter = () => {
    setIsLoading(true);
    setSearchText('');
    setDateRangeSelected([null, null]);
    setFilter({});
  };

  const onRefresh = () => {
    setLoadingRefresh(true); // Inicia el loading
    try {
      setIsLoading(true);
      setRefresh(true);
    } finally {
      setLoadingRefresh(false); // Finaliza el loading
    }
  };

  return (
    <>
      <AlertPop
        open={openAlert}
        setOpen={setOpenAlert}
        setInfo={setInfoAlert}
        info={infoAlert}
      />
      <div className="user-container">
        {message.text != '' && (
          <Alert type={message.type}>{message.text}</Alert>
        )}
        <div
          className="container-btn-survey"
          style={{ top: 0, marginBottom: '24px' }}
        >
          <Button
            key="reloadSurveyButton"
            onClick={onRefresh}
            secondary
            style={{ width: '202px' }}
          >
            Actualizar encuestas
          </Button>
          {/* <Button onClick={() => newSurvey()} style={{ width: '202px' }}>
      <span className="material-icons">add</span>
      Agregar nueva encuesta
    </Button> */}
        </div>
        <TableEncuesta
          data={survey}
          columns={columns}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          setIsLoading={setIsLoading}
          isLoadingData={isLoading}
          tableName="Encuestas"
          haveRowSelect={false}
          isDynamic
          onRelationSurvey={onRelationSurvey}
          onSearch={onSearch}
          dateRangeSelected={dateRangeSelected}
          setDateRangeSelected={setDateRangeSelected}
          resetFilter={resetFilter}
          filter={filter}
          expanded={expandedRows}
          setExpanded={setExpandedRows}
        />
        {refresh && (
          <>
            {isLoading ? (
              <Skeleton />
            ) : (
              <div className="reloadDate">
                <span>
                  Última actualización {moment(reloadDate).format('DD/MM/YYYY')}{' '}
                  a las {moment(reloadDate).format('HH:mm')}.{' '}
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SurveyTableContainer;

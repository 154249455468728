import React, { useCallback, useEffect, useState } from 'react';
import TableEncuesta from '../../../components/common/TableEncuesta';
import EmptyCard from '../../../components/common/Cards/emptyCard';
import empty from '../../../assets/images/empty.png';
import Button from '../../../components/common/Button';
import { useHistory } from 'react-router-dom';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import PropTypes from 'prop-types';
import 'survey-core/defaultV2.min.css';
import surveyThemeJson from '../../../utils/surveyThemeJson';
import SurveyAPI from '../../AnnouncementContainer/survey-api';
import Menu, { MenuContainer } from '../../../components/common/Menu';
import Alert from '../../../components/common/Alert';
import { exportExcell } from '../../../utils/reports';

import { SurveyModalRelationGroup } from '../../../components/common/Modal/SurveyRelationGroup';
import SurveysAPI from '../../SurveyContainer/survey-api';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import { IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AlertPop from '../../../components/common/AlertPop';

const LIMIT_PER_PAGE = 10;

const SurveyTable = ({ project }) => {
  const { announcementId, clientId } = project;
  const history = useHistory();
  const [surveys, setSurveys] = useState([]);

  const [isMenuOpen, setIsMenuOpen] = useState({ open: false, id: '' });
  const [messageAlert, setMessageAlert] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [reloadDate, setReloadDate] = useState('');
  const [dateRangeSelected, setDateRangeSelected] = useState([null, null]);
  const [filter, setFilter] = useState({});
  const [reloadDataTable, setReloadDataTable] = useState(false);

  const [openModalRelationGroup, setOpenModalRelationGroup] = useState(false);
  const [rowSelected, setRowSelected] = useState([]);
  const [clientID, setClientID] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [infoAlert, setInfoAlert] = useState({
    title: '',
    description: '',
    actions: {},
  });

  const [expandedRows, setExpandedRows] = useState({});

  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [loadingNewSurvey, setLoadingNewSurvey] = useState(false);

  const groupedData = (data) => {
    return data.reduce((acc, item) => {
      const { groupName } = item;
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(item);
      return acc;
    }, {});
  };

  const fetchSurveys = useCallback(async () => {
    let arrData = [];
    let totalData = 0;
    let dateRefresh = '';
    let count = 0;

    await SurveysAPI.getAllSurveys(
      currentPage,
      LIMIT_PER_PAGE,
      filter,
      refresh,
      clientId
    )
      .then((response) => {
        if (response.data.data.length > 0) {
          const groups = groupedData(response.data.data);

          Object.entries(groups).forEach(([groupName, data]) => {
            console.log(`Grupo: ${groupName}`);
            console.log('Data:', data);
            if (groupName === 'Sin grupo') {
              data.map((item, index) => {
                return arrData.push({
                  nombre: item?.nombre ?? '-',
                  id: item?.idEncuesta ?? '-',
                  fechaActivacion: item?.fechaActivacion ?? '-',
                  dirigidoA: item?.dirigidoA ?? '-',
                  tipoDeEncuesta: item?.tipoDeEncuesta ?? '-',
                  cantidadPreguntas: item?.cantidadPreguntas ?? '-',
                  estado: item?.estado ?? '-',
                  idEncuesta: item?.idEncuesta ?? '-',
                  urlEditar: item?.urlEditar ?? '-',
                  verEncuesta: item?.verEncuesta ?? '-',
                  verResultadosEncuesta: item?.verResultadosEncuesta ?? '-',
                  diasActiva: item?.diasActiva ?? '-',
                  groupName: groupName + index,
                  rowIndex: count++,
                  rowGroup: [],
                  haveGroup: false,
                });
              });
              console.log('arrData 1', arrData);
            } else {
              arrData.push({
                nombre: groupName ?? '-',
                id: groupName,
                fechaActivacion: '-',
                dirigidoA: '-',
                tipoDeEncuesta: '-',
                cantidadPreguntas: '-',
                estado: '-',
                idEncuesta: '-',
                urlEditar: '-',
                verEncuesta: '-',
                verResultadosEncuesta: '-',
                diasActiva: '-',
                groupName: groupName,
                rowIndex: count++,
                rowGroup: data.map((item, index) => {
                  return {
                    nombre: item?.nombre ?? '-',
                    id: item?.idEncuesta ?? '-',
                    fechaActivacion: item?.fechaActivacion ?? '-',
                    dirigidoA: item?.dirigidoA ?? '-',
                    tipoDeEncuesta: item?.tipoDeEncuesta ?? '-',
                    cantidadPreguntas: item?.cantidadPreguntas ?? '-',
                    estado: item?.estado ?? '-',
                    idEncuesta: item?.idEncuesta ?? '-',
                    urlEditar: item?.urlEditar ?? '-',
                    verEncuesta: item?.verEncuesta ?? '-',
                    verResultadosEncuesta: item?.verResultadosEncuesta ?? '-',
                    diasActiva: item?.diasActiva ?? '-',
                    haveGroup: true,
                    groupName: groupName,
                    rowIndex: groupName + index,
                  };
                }),
              });
            }
          });

          totalData = response.data.pagination.totalPages;

          if (refresh) {
            dateRefresh = response.data.lastRefresh;
          }
        }
        setSurveys(arrData);

        setTotalPages(totalData);

        setReloadDate(dateRefresh);
      })
      .catch((error) => {
        setMessageAlert({
          type: 'error',
          text: 'Error al cargar las encuestas.',
        });
      })
      .finally(() => {
        setTimeout(() => {
          setMessageAlert({ type: '', text: '' });
        }, 5000);
        setIsLoading(false);
      });
  }, [currentPage, filter, refresh === true, reloadDataTable, clientId]);

  useEffect(() => {
    if (refresh) {
      fetchSurveys();
    }
  }, [refresh, fetchSurveys]);

  useEffect(() => {
    fetchSurveys();
  }, [currentPage, filter, clientId, reloadDataTable, fetchSurveys]);

  useEffect(() => {
    if (searchText !== '') {
      setIsLoading(true);
      setFilter({
        searchText: searchText,
        ...filter,
      });
    } else if (
      dateRangeSelected.length === 2 &&
      dateRangeSelected[1] !== null
    ) {
      setIsLoading(true);
      setFilter({
        dateRange: dateRangeSelected,
        ...filter,
      });
    }
  }, [dateRangeSelected, searchText]);

  const onMenuClick = (id) => {
    setIsMenuOpen((prevOpenMenus) => ({
      [id]: !prevOpenMenus[id],
    }));
  };

  const toggleRowExpanded = (rowIndex) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  const deleteGroup = async (groupName) => {
    setIsLoading(true);
    setIsMenuOpen({});
    const payload = {
      action: 'delete',
      groupName: groupName,
    };
    try {
      await SurveyAPI.relationGroup(payload).then(() => {
        setSurveys([]);
        setReloadDataTable((prev) => !prev);
        setMessageAlert({
          type: 'success',
          text: 'Grupo de encuestas eliminado correctamente.',
        });
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      });
    } catch (error) {
      setMessageAlert({
        type: 'error',
        text: 'Error al eliminar el grupo de encuestas.',
      });
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const deleteByGroup = async (groupName, clientId, idEncuesta) => {
    setIsLoading(true);
    setIsMenuOpen({});
    const payload = {
      action: 'remove',
      clientId: clientId,
      groupName: groupName,
      surveyIds: [idEncuesta],
    };
    try {
      await SurveyAPI.relationGroup(payload).then(() => {
        setSurveys([]);
        setReloadDataTable((prev) => !prev);
        setMessageAlert({
          type: 'success',
          text: 'Encuesta eliminada del grupo de encuestas correctamente.',
        });
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      });
    } catch (error) {
      setMessageAlert({
        type: 'error',
        text: 'Error al eliminar encuesta del grupo de encuestas.',
      });
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const surveyInZoho = (url) => {
    window.location.href = url;
  };

  const handleCopy = (urlToCopy) => {
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        setOpenAlert(true);
        setInfoAlert({
          title: (
            <CheckCircleOutline
              style={{ fontSize: '50px', color: 'limegreen' }}
            />
          ),
          description: 'URL copiada al portapapeles.',
          actions: {
            onSuccess: 'Aceptar',
            onSuccessClick: () => setIsMenuOpen({}),
          },
        });
      })
      .catch((error) => {
        setOpenAlert(true);
        setInfoAlert({
          title: <HighlightOff style={{ fontSize: '50px', color: 'red' }} />,
          description: 'Error al copiar la URL.',
          actions: {
            onSuccess: 'Aceptar',
            onSuccessClick: () => setIsMenuOpen({}),
          },
        });
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'nombre',
        width: 180,
      },
      {
        Header: 'Fecha de activación',
        width: 100,
        accessor: 'fechaActivacion',
        Cell: ({ value }) => (value === 'Fecha inválida' ? '-' : value),
      },
      {
        Header: 'Dirigido a',
        accessor: 'dirigidoA',
        width: 90,
        Cell: ({ value }) =>
          value === '' ? '-' : value === 'both' ? 'Ambos' : value,
      },
      { Header: 'Tipo de encuesta', accessor: 'tipoDeEncuesta', width: 150 },
      {
        Header: 'Número de preguntas',
        accessor: 'cantidadPreguntas',
        width: 90,
      },
      {
        Header: 'Estado',
        accessor: 'estado',
        width: 90,
        Cell: ({ value }) => {
          return (
            <div className="div-container-status-table">
              <div
                className={`point-status ${
                  value === true ? 'green' : value !== '-' ? 'with-border' : '-'
                }`}
              />
              <span>
                {value === true ? 'Activa' : value !== '-' ? 'Inactiva' : '-'}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'Acción',
        width: 150,
        Cell: ({ row: { original } }, i) => {
          const listItems = [];
          if (original.rowGroup?.length > 0) {
            listItems.push({
              text: 'Desagrupar',
              key: 'desagrupar',
              action: () => deleteGroup(original.groupName),
            });
          } else {
            listItems.push(
              {
                text: 'Copiar URL de encuesta',
                key: 'verDetalle',
                status: original.verEncuesta === '' ? 'disabled' : 'active',
                action: () => handleCopy(original.verEncuesta),
              },
              {
                text: 'Ver resultados en Zoho',
                key: 'verResultados',
                tooltip: 'Los resultados no se encuentran disponibles',
                placement: 'left',
                status:
                  original.verResultadosEncuesta === '' ? 'disabled' : 'active',
                action: () => surveyInZoho(original.verResultadosEncuesta),
              },
              {
                text: 'Editar encuesta en Zoho',
                key: 'editar',
                status: original.urlEditar === '' ? 'disabled' : 'active',
                action: () => surveyInZoho(original.urlEditar),
              }
            );
            if (original.haveGroup) {
              listItems.push({
                text: 'Quitar del grupo',
                key: 'quitar',
                action: () =>
                  deleteByGroup(
                    original.groupName,
                    clientId,
                    original.idEncuesta
                  ),
              });
            }
          }

          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <MenuContainer
                classActive={`${
                  isMenuOpen[original.id] ? 'active-action' : ''
                }`}
                onClick={() => onMenuClick(original.id)}
                key={original.idEncuesta}
              >
                <Menu
                  open={isMenuOpen[original.id] ?? false}
                  items={listItems}
                  id={original.id}
                  row={original.rowGroup}
                />
              </MenuContainer>
              <div>
                {original.rowGroup?.length > 0 ? (
                  <IconButton
                    style={{ color: '#0bb5e7' }}
                    onClick={() => toggleRowExpanded(original.rowIndex)}
                  >
                    <ExpandMoreIcon
                      style={{
                        transform: `${
                          expandedRows[original.rowIndex]
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)'
                        }`,
                        transition: 'transform 0.3s ease',
                      }}
                    />
                  </IconButton>
                ) : (
                  <div style={{ width: '48px', height: '48px' }}></div>
                )}
              </div>
            </div>
          );
        },
      },
    ],
    [history, isMenuOpen, expandedRows]
  );

  const newSurvey = async (idClient, idAnnouncement) => {
    setLoadingNewSurvey(true); // Inicia el loading
    try {
      const campaignId = '';
      const {
        data: { data: response },
      } = await SurveysAPI.newSurvey(idClient, idAnnouncement, campaignId);
      window.location.href = response.redirectURL;
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        'Error al obtener enlace para nueva encuesta.';
      setMessageAlert({ type: 'error', text: errorMessage });
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } finally {
      setLoadingNewSurvey(false); // Finaliza el loading
    }
  };

  const onRelationSurvey = (survey) => {
    setRowSelected(survey);
    setOpenModalRelationGroup(true);
  };

  const onSearch = (value) => {
    setSearchText(value);
  };

  const resetFilter = () => {
    setIsLoading(true);
    setSearchText('');
    setDateRangeSelected([null, null]);
    setFilter({});
  };

  const onRefresh = () => {
    setLoadingRefresh(true); // Inicia el loading
    try {
      setIsLoading(true);
      setRefresh(true);
    } finally {
      setLoadingRefresh(false); // Finaliza el loading
    }
  };

  return (
    <>
      <AlertPop
        open={openAlert}
        setOpen={setOpenAlert}
        setInfo={setInfoAlert}
        info={infoAlert}
      />
      <SurveyModalRelationGroup
        openModal={openModalRelationGroup}
        setOpenModal={setOpenModalRelationGroup}
        setIsLoading={setIsLoading}
        rowSelected={rowSelected}
        setRowSelected={setRowSelected}
        setReloadDataTable={setReloadDataTable}
        action={'add'}
        clientId={clientId}
      />
      {showAlert && <Alert type={messageAlert.type}>{messageAlert.text}</Alert>}
      <div className="container-btn-survey">
        <Button
          key="reloadSurveyButton"
          onClick={onRefresh}
          secondary
          style={{ width: '202px' }}
          disabled={loadingRefresh} // Deshabilita mientras carga
        >
          {loadingRefresh ? (
            <span className="material-icons loading-spinner">
              hourglass_empty
            </span>
          ) : (
            'Actualizar encuestas'
          )}
        </Button>

        <Button
          onClick={() => newSurvey(clientId, announcementId)}
          key="newSurveyButton"
          style={{ width: '202px' }}
          disabled={loadingNewSurvey} // Deshabilita mientras carga
        >
          {loadingNewSurvey ? (
            <span className="material-icons loading-spinner">
              hourglass_empty
            </span>
          ) : (
            <>
              <span className="material-icons">add</span>
              Nueva encuesta
            </>
          )}
        </Button>
      </div>

      <TableEncuesta
        data={surveys}
        columns={columns}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        setIsLoading={setIsLoading}
        isLoadingData={loading}
        tableName="Encuestas"
        haveRowSelect={true}
        isDynamic
        onRelationSurvey={onRelationSurvey}
        onSearch={onSearch}
        dateRangeSelected={dateRangeSelected}
        setDateRangeSelected={setDateRangeSelected}
        resetFilter={resetFilter}
        filter={filter}
        expanded={expandedRows}
        setExpanded={setExpandedRows}
        rowSelected={rowSelected}
        setRowSelected={setRowSelected}
      />

      {refresh && (
        <>
          {loading ? (
            <Skeleton />
          ) : (
            <div className="reloadDate">
              <span>
                Última actualización {moment(reloadDate).format('DD/MM/YYYY')} a
                las {moment(reloadDate).format('HH:mm')}.
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};

const SurveyTableComponent = ({ surveyQuestions, isReadOnly }) => {
  const myCss = {
    title: 'sv-title',
    description: 'sv-description',
    navigation: {
      complete: 'btn-complete',
    },
  };

  useEffect(() => {
    const survey = new Model(surveyQuestions);
    survey.applyTheme(surveyThemeJson);
    survey.completeText = 'Enviar';
    survey.widthMode = 'responsive';
    survey.css = myCss;

    if (isReadOnly) {
      survey.mode = 'display';
    }

    setSurveyModel(survey);
  }, [surveyQuestions, isReadOnly]);

  const [surveyModel, setSurveyModel] = useState(null);

  const surveyContainerStyle = {
    width: '80%',
    height: '95%',
    padding: '10px',
    margin: 'auto',
  };

  return (
    <div className="survey-container" style={surveyContainerStyle}>
      {surveyModel && <Survey model={surveyModel} />}
    </div>
  );
};

SurveyTableComponent.propTypes = {
  surveyQuestions: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

SurveyTableComponent.defaultProps = {
  isReadOnly: false,
};

export default SurveyTable;
